<template>
  <div>
    <Header2022 :navbg='true'></Header2022>
    <div class="content">
      <!-- <Banner :bannerList="bannerList" height="650px"></Banner> -->
      <div class="banner">
        <img src="../../assets/images/life-culture.jpg" alt="">
      </div>
      <div class="qywh page">
        <div class="left">
          <div class="name">
            WHO WE ARE
          </div>
          <div class="title">
            <span class="hr"></span>
            战略规划
          </div>
          <div class="content">
            我们忠于创新的建造技术，让居住体验源于生活、回归生活，为生活爱好者提供优质、贴心、品质的生活配套服务，为城市问题及城市建设发展提供解决方案，通过智慧社区管理体系，逐步完善“小院生活”消费业态，撬动行业变革，让正确的事加速发生。我们将竭尽全力推动绿色健康的院落式人居环境回归城市生活，并且以科技与创新引领行业变革，以全产业链居家服务改变行业结构，最终逐步走入健康蓝色新三产，迎接合作共贏新未来。
          </div>
          <div class="title">
            <span class="hr"></span>
            企业文化
          </div>
          <div class="content">
            在小院，我们彼此称呼对方为“伙伴”，我们的伙伴是小院的核心和灵魂，正是你们与客户的每一次连接、每一次服务成就了小院的形象。所以，我们的伙伴一定是经过精挑细选的，你们来到了小院生活的大家庭，那么你们一定是和我们有着共同目标的伙伴，具有服务精神、热忱敬业、积极向上、不怕困难，为实现我们的共同目标而努力。
            小院也一直致力培养、支持我们的伙伴，为每一位伙伴制定合理且有挑战的职业规划、同时，我们会有相关培训，让所有伙伴和小院一起发展壮大，最终实现自己的职业理想。

            小院努力成为伙伴们的最佳工作场所，是每一位人才都希望加入并打造自己职业生涯的地方，在这里，我们充满活力、热情、包容、亲密、相互尊重和信任。
            我们有健全的薪酬福利体系，为每一位伙伴提供同行业有竞争力的薪酬和福利，保证每一位伙伴为小院的付出都能体现在薪酬上。

            我们还有完善的激励计划，每一位伙伴本职工作之外的任何付出，都将会被我们铭记，并有相应的奖励，我们努力打造积极奋进、互相帮助、不推诿的工作氛围。

            在小院，只要努力，每一位伙伴都有机会获得公司股权奖励。
          </div>

        </div>
        <div class="right">
          <div class="item">
            <img src="../../assets/images/qywh1.png" alt="">
            <div class="title">Innovation 创 新</div>
          </div>
          <div class="item">
            <img src="../../assets/images/qywh3.png" alt="">
            <div class="title">Service 服 务</div>
          </div>
          <div class="item">
            <img src="../../assets/images/qywh2.png" alt="">
            <div class="title">Cooperation 合 作</div>
          </div>
          

        </div>
      </div>
    </div>
    <Footer2022></Footer2022>
  </div>
</template>

<script>
import Header2022 from '@/components/header2022.vue'
import Footer2022 from '@/components/footer2022.vue'
export default {
  data () {
    return {

    }
  },
  components: {
    Header2022,
    Footer2022,
  }
}
</script>

<style lang="scss" scoped>
.content{
  padding-top: 64px;
}
.qywh.page {
  display: flex;
  justify-content: space-between;
  margin-top: 76px;
  color: #303233;
}

.qywh .left {
  flex: 1;
  margin-right: 80px;
}

.qywh .left .name {
  font-size: 36px;
  line-height: 36px;
}

.qywh .left .title {
  font-size: 24px;
  margin-top: 30px;
}

.qywh .left .title .hr {
  width: 50px;
  height: 4px;
  background-color: #06C15F;
  display: block;
  margin-bottom: 30px;
}

.qywh .left .content {
  font-size: 16px;
  margin-top: 46px;
  line-height: 32px;
  margin-bottom: 75px;
}

.qywh .right {
  margin-top: 100px;
  width: 320px;
}

.qywh .right .item {
  width: 100%;
  height: 90px;
  background-color: #06C15F;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  box-sizing: border-box;
  align-items: center;
  color: #fff;
  font-size: 18px;
}

.qywh .right .item img {
  width: 60px;
  height: auto;
}
</style>